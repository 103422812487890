<script>
export default {
  name: "ArticlesRadioGroup",

  props: {
    disabled: Boolean,
  },

  data() {
    return {
      currentRadio: "enter",
    };
  },

  watch: {
    currentRadio(newVal) {
      this.$emit("update-radio", newVal);
    },
  },
};
</script>

<template>
  <div class="articles__group">
    <label :class="{ disabled: disabled }" class="articles__radio">
      <input
        :disabled="disabled"
        type="radio"
        value="enter"
        v-model="currentRadio"
      />
      <span></span>
      Ввод через Enter
    </label>

    <label :class="{ disabled: disabled }" class="articles__radio">
      <input
        :disabled="disabled"
        type="radio"
        value="space"
        v-model="currentRadio"
      />
      <span></span>
      Ввод через Пробел
    </label>
  </div>
</template>

<style lang="scss">
.articles {
  &__group {
    margin-bottom: 16px;
    display: flex;
    gap: 12px 24px;
    flex-wrap: wrap;
  }

  &__radio {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    @include font-description-0;

    > input {
      display: none;

      &:checked + span:before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        background: var(--grey-70);
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 1s ease;
      }
    }

    span {
      width: 16px;
      height: 16px;
      border: 2px solid var(--grey-30);
      border-radius: 50%;
      display: inline-block;
      position: relative;
      transition: 1s ease;
      margin-right: 8px;
    }

    // & + label {
    //   margin-left: 24px;

    //   @media (max-width: $mobile-width){

    //   }
    // }

    &.disabled {
      color: var(--grey-30);

      input:checked + span:before {
        background: var(--grey-30);
      }
    }
  }
}
</style>
