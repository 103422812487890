<script>
export default {
  props: {
    color: { type: String, default: '#fff' },
    backgroundColor: { type: String, default: 'var(--blue-100)' },
    timeout: {type: Number, default: 3000 },
    show: Boolean,
  },

  watch: {
    show(newVal) {
      if (newVal) {
        this.hideSnackbar();
      }
    },
  },

  methods: {
    hideSnackbar() {
      setTimeout(() => {
        this.$emit('hide')
      }, this.timeout)
    },
  },
};
</script>

<template>
  <div class="snackbar" :class="!show ? 'snackbar__hide' : ''" :style="{ 'background-color': backgroundColor }">
    <div class="snackbar__body">
      <p class="snackbar__description" :style="{ color: color }">
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.snackbar {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
  width: auto;
  border-radius: 4px;
  opacity: 1;
  visibility: visible;
  transition: .5s;

  &__hide{
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &__body {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__description {
    word-break: break-all;
    @include font-description-1;
  }
}
</style>