<script>
import AppTag from "@/components/ui/AppTag.vue";
import { highlightOverlap } from "@/utils/highlightOverlap.js"; 

export default {
  name: "ArticlesFormResultItems",

  components: {
    AppTag,
  },

  props: {
    items: Array,
    status: String,
  },

  computed: {
    statusColor() {
      switch (this.status) {
        case "maybe":
          return "orange";
        case "bad":
          return "red";
        default:
          return "green";
      }
    },

    filterItems() {
      if (this.status === "maybe") {
        return this.items[0].values;
      } else {
        return this.items;
      }
    },
  },

  methods: {
    highlightOverlap,
  }

};
</script>

<template>
  <div class="articles-items--match-section" v-if="status === 'maybe'">
    <div class="articles-items" v-for="(item, idx) in items" :key="idx">
      <p class="articles-items__match-name">{{ item.key }}:</p>
      <div
        class="articles-items__matched"
        v-for="(el, i) in item.values"
        :key="i"
      >
        <AppTag class="articles-items__tag" :color="statusColor" label="">
          <template #match>
            <span v-html="highlightOverlap(item.key, el)"></span>
          </template>
        </AppTag>
      </div>
    </div>
  </div>
  <div v-else class="articles-items">
    <div v-for="(item, idx) in items" :key="idx">
      <AppTag class="articles-items__tag" :color="statusColor" :label="item" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.articles-items {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;

  &__tag {
    height: 28px;

    span{
      color: var(--grey-60);
    }
  }

  &__matched {
    background-color: var(--orange-10);
    padding-block: 2px;
    border-radius: 8px;
  }

  &__match-name {
    color: var(--grey-70);
    @include font-body-bold-3;
  }

  &--match-section {
    display: flex;
    flex-direction: column;
    gap: 16px 0;
  }
}
</style>