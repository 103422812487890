function findOverlap(value, str) {
  if (value.length < 2) {
    return "";
  }
  if (str.startsWith(value) || str.endsWith(value) || str.indexOf(value) >= 0) {
    return value;
  }
  const overlapLeft = findOverlap(value.substring(0, value.length - 1), str);
  const overlapRight = findOverlap(value.substring(1, value.length), str);
  return overlapLeft.length > overlapRight.length ? overlapLeft : overlapRight;
}

function findOverlapIndexes(value, str) {
  const overlap = findOverlap(value, str);
  if (!overlap) {
    return [0, 0];
  }
  const startI = str.indexOf(overlap);
  return [startI, startI + overlap.length - 1];
}

function splitByOverlap(value, str) {
  const [start, end] = findOverlapIndexes(value, str);
  if (start === 0 && end === 0) {
    return [str, "", ""];
  }
  return [
    str.substring(0, start),
    str.substring(start, end + 1),
    str.substring(end + 1),
  ];
}

export function highlightOverlap(value, str) {
  let word = splitByOverlap(value, str);
  word[1] &&= `<span style="color: orange;">${word[1]}</span>`;
  return word.join("");
}
