<script>
import { articles_faq } from "@/components/jsons/articles-faq";

export default {
  name: "ArticlesFaq",

  data() {
    return {
      faqItems: articles_faq,
      faqPanel: [0],
    };
  },
};
</script>

<template>
  <div class="articles__faq">
    <v-expansion-panels v-model="faqPanel" multiple>
      <v-expansion-panel
        v-for="(faq, i) in faqItems"
        :key="i"
        class="articles__faq-row"
      >
        <v-expansion-panel-header>
          <h3 class="articles__faq-title">{{ faq.title }}</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-for="faqItem in faq.body_items"
          :key="faqItem.id"
        >
          <div class="articles__faq-body">
            <div class="articles__faq-body-top">
              <img
                class="articles__faq-img"
                :src="faqItem.icon"
                :alt="faqItem.item_title"
              />
              <h4
                class="articles__faq-body-title articles__faq-body-title_mobile"
                v-if="faqItem?.item_title"
              >
                {{ faqItem.item_title }}
              </h4>
            </div>

            <div>
              <h4 class="articles__faq-body-title" v-if="faqItem?.item_title">
                {{ faqItem.item_title }}
              </h4>
              <div v-html="faqItem.item_description"></div>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<style lang="scss">
.articles {
  &__faq {
    margin-top: 24px;

    &-title {
      @include font-body-bold-1;

      @media (max-width: $tablet-width) {
        @include font-body-bold-2;
      }
    }

    &-img {
      max-width: 60px;
      max-height: 60px;
      object-fit: contain;
      margin-right: 16px;

      @media (max-width: $tablet-width) {
        max-width: 40px;
        max-height: 40px;
      }

      @media (max-width: $mobile-width) {
        margin-right: 8px;
        max-width: 32px;
        max-height: 32px;
      }
    }

    &-row {
      margin-bottom: 20px;
      border: 2px solid var(--grey-20);
      box-shadow: none;
      border-radius: 8px !important;

      &:before {
        content: unset;
      }

      &:after {
        border: unset !important;
      }
    }

    &-body {
      display: flex;
      align-items: center;

      @include font-body-3;

      @media (max-width: $mobile-width) {
        flex-direction: column;
        align-items: flex-start;
      }

      &-top {
        display: flex;
        align-items: center;
      }

      &-title {
        @include font-body-bold-1;
        margin-bottom: 4px;

        @media (max-width: $tablet-width) {
          @include font-body-bold-3;
        }

        @media (max-width: $mobile-width) {
          display: none;
        }

        &_mobile {
          display: none;

          @media (max-width: $mobile-width) {
            display: inline-block;
          }
        }
      }

      p {
        margin: unset;
      }
    }
  }

  .v-expansion-panel-header {
    position: relative;
    padding: 16px;

    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      display: block;
      background: url("../../../assets/img/icons/chevron-1w.svg");
      opacity: 1;
      right: 16px;
      left: unset;
      top: 50%;
      transform: translateY(-50%);
      transition: transform 0.2s ease-in-out;
    }

    &--active {
      &:before {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .v-expansion-panel-content__wrap {
    padding: 16px;
  }
}
</style>
