<script>
import ArticlesFormResultItems from "@/components/resources/articles/ArticlesFormResultItems.vue";
import AppButton from "@/components/ui/AppButton.vue";

export default {
  name: "ArticlesFormResult",

  components: {
    ArticlesFormResultItems,
    AppButton,
  },

  props: {
    result: Object,
  },

  data() {
    return {
      copyText: "Копировать",
      copyImage: require("@/assets/img/icons/copy-icon.svg"),
    };
  },

  computed: {
    sections() {
      return [
        { title: "Найдено", items: this.result.good, status: "good" },
        {
          title: "Частично совпадают",
          items: this.result.maybe,
          status: "maybe",
        },
        { title: "Не найдено", items: this.result.bad, status: "bad" },
      ];
    },
  },

  methods: {
    copySection(section) {
      let textToCopy = "";
      if (section.status == "maybe") {
        textToCopy = section.items
          .reduce((accumulator, currentItem) => {
            return accumulator.concat(currentItem.key.replace(/\n/g, '\\n'));
          }, [])
          .join("\n");
      } else {
        textToCopy = section.items.join("\n");
      }

      try {
        navigator.clipboard.writeText(textToCopy);
        this.copyText = "Скопировано";

        setTimeout(() => {
          this.copyText = "Копировать";
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<template>
  <div class="articles-result">
    <h2 class="articles-result__title">Результаты проверки</h2>

    <div
      class="articles-result__section"
      v-for="(section, idx) in sections"
      :key="idx"
    >
      <div class="articles-result__section-head">
        <p class="articles-result__section-title">{{ `${section.title} · ${section.items.length}` }}</p>
        <div
          class="articles-result__section-copy"
          @click="copySection(section)"
        >
          <img :src="copyImage" alt="copy-icon" />
          <span>{{ copyText }}</span>
        </div>
      </div>

      <ArticlesFormResultItems
        :status="section.status"
        :items="section.items"
      />
    </div>

    <div class="articles-result__btns">
      <AppButton
        class="articles-result__btn"
        color="secondary-blue"
        label="Проверить документ заново"
        @click="$emit('resetForm', 'resetArticles')"
      />

      <AppButton
        class="articles-result__btn"
        color="blue"
        label="Новая проверка"
        @click="$emit('resetForm', 'resetAll')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.articles-result {
  color: var(--grey-70);

  &__title {
    @include font-headline-1;
    margin-bottom: 32px;

    @media (max-width: $mobile-width) {
      @include font-headline-2;
    }
  }

  &__section {
    margin-bottom: 32px;

    &-title {
      @include font-headline-2;
    }

    &-copy {
      position: relative;
      z-index: 10;
      cursor: pointer;
      margin-left: 8px;

      > span {
        display: block;
        position: absolute;
        left: 50%;
        top: -30px;
        transform: translateX(-50%);

        padding: 4px 8px;
        border-radius: 4px;

        opacity: 0;
        visibility: hidden;

        transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
        color: #fff;
        background-color: var(--grey-70);

        @include font-description-1;

        &:before {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          background-color: var(--grey-70);
          left: 50%;
          transform: translateX(-50%) rotate(45deg);
          bottom: -3px;
          z-index: -1;
        }
      }

      &:hover {
        > span {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &__section-head {
    display: flex;
    margin-bottom: 8px;
  }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  &__btn {
    max-width: fit-content;

    @media (max-width: $mobile-width) {
      max-width: 100%;
    }
  }
}
</style>
