export const articles_faq = [
  {
    id: 1,
    title: "Как это работает?",
    body_items: [
      {
        id: 1,
        item_title: "Загрузите документ",
        item_description:
          "<p>Выберите файл в формате PDF (читаемый, не сканированный) или DOCX в поле «Загрузите документ». Сервис поддерживает все основные типы разрешительных документов</p>",
        icon: require("@/assets/img/icons/faq-icons-articles/upload.webp"),
      },
      {
        id: 2,
        item_title: "Введите артикулы / модели",
        item_description:
          "<p>Скопируйте данные из инвойса, упаковочного листа или другого источника и вставьте их в окно «Поиск артикулов». Каждый артикул должен содержать не менее 5 знаков</p>",
        icon: require("@/assets/img/icons/faq-icons-articles/input.webp"),
      },
      {
        id: 3,
        item_title: "Нажмите «Проверить»",
        item_description: `<p>Система мгновенно проанализирует документ и выдаст результат в трёх категориях:</p>
            <ul> 
                <li>найденные артикулы - полное совпадение</li>
                <li>частичные совпадения - артикулы, схожие по структуре</li>
                <li>не найденные артикулы - отсутствуют в документе</li>
            </ul>`,
        icon: require("@/assets/img/icons/faq-icons-articles/check.webp"),
      },
    ],
  },
  {
    id: 2,
    title: "Кому это нужно?",
    body_items: [
      {
        id: 1,
        item_title: "Инструмент незаменим для:",
        item_description: `<ul>
                <li>участников ВЭД и таможенных представителей</li>
                <li>органов по сертификации и организаций, работающих с маркировкой «Честный знак»</li>
                <li>любых специалистов, которые хотят избежать ошибок при оформлении разрешительной документации</li>
            </ul>`,
        icon: require("@/assets/img/icons/faq-icons-articles/instrument.webp"),
      },
      {
        id: 2,
        item_title:
          "Исключает риск штрафов и минимизирует ошибки при оформлении:",
        item_description: `<ul>
                    <li>за декларирование товаров, не указанных в разрешительных документах, предусмотрен штраф до 300 000 ₽ (ст. 16.3 КоАП РФ). Сервис помогает исключить такие ситуации</li>
                    <li>проверьте, все ли артикулы внесены в сертификат или декларацию, перед подачей документов</li>
                </ul>`,
        icon: require("@/assets/img/icons/faq-icons-articles/risk.webp"),
      },
      {
        id: 3,
        item_title: "Преимущества:",
        item_description: `<ul>
                    <li>экономит время: автоматизация рутинной проверки</li>
                    <li>точный: минимизация человеческого фактора</li>
                    <li>открытый: никакой регистрации — сервис доступен всем</li>
                </ul>`,
        icon: require("@/assets/img/icons/faq-icons-articles/advantages.webp"),
      },
    ],
  },
  {
    id: 3,
    title: "Кто создал инструмент?",
    body_items: [
      {
        id: 1,
        item_title: "",
        item_description:
          "<p>«Поиск по артикулам» разработал Vedexx — лицензированный таможенный представитель и эксперт в области ВЭД. Мы сами активно используем этот инструмент в работе, чтобы гарантировать его эффективность и надежность. Уже сейчас он освобождает 2 часа в день у наших сотрудников.</p>",
        icon: require("@/assets/img/icons/faq-icons-articles/search.webp"),
      },
      {
        id: 2,
        item_title: "",
        item_description:
          "<p>Сервис не требует ввода личных данных и доступен всем пользователям. Детали работы алгоритма и меры защиты информации вы можете уточнить у наших специалистов.</p>",
        icon: require("@/assets/img/icons/faq-icons-articles/protect.webp"),
      },
      {
        id: 3,
        item_title: "",
        item_description:
          "Попробуйте «Поиск по артикулам» уже сегодня — убедитесь, как легко избежать ошибок и сэкономить время! <br> С Vedexx ваша работа с документами становится прозрачной и безопасной.",
        icon: require("@/assets/img/icons/faq-icons-articles/vdx.webp"),
      },
    ],
  },
];
