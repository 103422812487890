<script>
import AppTextarea from "@/components/ui/AppTextarea.vue";
import AppButton from "@/components/ui/AppButton.vue";
import AppTag from "@/components/ui/AppTag.vue";
import AppInput from "@/components/ui/AppInput.vue";
import AppSnackBar from "@/components/ui/AppSnackBar.vue";

export default {
  name: "ArticlesSearchField",

  components: {
    AppTextarea,
    AppButton,
    AppTag,
    AppInput,
    AppSnackBar,
  },

  props: {
    file: {
      type: File,
      default: null,
    },

    loader: Boolean,

    phrases: {
      type: Array,
      default: () => [],
    },

    mode: {
      type: String,
      default: "enter",
    },
  },

  data() {
    return {
      currentPhrase: "",
      showAllPhrases: false,
      visiblePhrases: 100,
      inputRules: [() => "Запрос от 5 знаков"],
      showError: false,
      invalidPhrasesCount: null,
      showNotify: false,
    };
  },

  computed: {
    additionalCount() {
      return this.phrases.length > 2 ? this.phrases.length - 2 : 0;
    },

    textareaRows() {
      return this.currentPhrase.split("\n").length > 2 ? 3 : 1;
    },

    displayedPhrases() {
      return this.phrases.slice(0, this.visiblePhrases);
    },

    showMoreButton() {
      return this.visiblePhrases < this.phrases.length;
    },

    showDescription() {
      return !this.showError ? "Запрос от 5 знаков. Количество запросов не ограничено" : "";
    },

    modeText(){
      return this.mode == 'space' ? 'Пробел' : 'Enter'
    }
  },

  methods: {
    putArticle() {
      if (this.currentPhrase.length >= 5) {
        const newPhrases = [
          {
            id: this.phrases.length + 1,
            value: this.currentPhrase,
          },
          ...this.phrases,
        ];
        this.$emit("update-phrases", newPhrases);
        this.showError = false;
        this.currentPhrase = "";
      } else {
        this.showError = true;
        this.validateInput();
      }
    },

    removeArticlePhrase(idx) {
      const newPhrases = this.phrases.filter((phrase, index) => index !== idx);
      this.$emit("update-phrases", newPhrases);
    },

    pasteArticles(e) {
      const pastedText = e.clipboardData.getData("text/plain");
      let articles;
      let invalidCount = 0;

      if (this.mode === "enter") {
        articles = pastedText
          .split("\n")
          .map(article => article.trim())
          .filter(article => {
            if (article.length >= 5) {
              return true;
            } else {
              invalidCount++;
              return false;
            }
          });
      } else if (this.mode === "space") {
        articles = pastedText
          .split(/[\s\t\n]+/)
          .map(article => article.trim())
          .filter(article => {
            if (article.length >= 5) {
              return true;
            } else {
              invalidCount++;
              return false;
            }
          });
      }

      this.invalidPhrasesCount = invalidCount;

      this.showNotify = true;

      const newPhrases = [
        ...articles.map((article, index) => ({
          id: this.phrases.length + index + 1,
          value: article,
        })),
        ...this.phrases,
      ];

      this.$emit("update-phrases", newPhrases);
    },

    handleBackspace() {
      if (this.currentPhrase.length === 0) {
        const newPhrases = this.phrases.slice(0, -1);
        this.$emit("update-phrases", newPhrases);
      }
    },

    showMorePhrases() {
      this.visiblePhrases += 10;
    },

    handleKeydown(e) {
      if (this.mode === "enter" && e.key === "Enter") {
        e.preventDefault();
        this.putArticle();
      } else if (this.mode === "space" && e.key === " ") {
        e.preventDefault();
        this.putArticle();
      } else if (e.key === "Backspace") {
        this.handleBackspace();
      }

      if (this.mode === "space" && e.key === "Enter") {
        e.preventDefault();
      }
    },

    validateInput() {
      this.$refs.articleInput?.validate();
      this.$refs.articleTextarea?.validate();
    },

    handleCheck() {
      this.putArticle();
      this.$emit("get-articles");
    },
  },
};
</script>
<template>
  <div class="articles__search">
    <AppSnackBar
      class="articles__snackbar"
      color="var(--grey-70)"
      backgroundColor="var(--green-10)"
      :show="!invalidPhrasesCount && showNotify"
      @hide="showNotify = false"
    >
      Добавлено артикулов для проверки: {{ phrases.length }}
    </AppSnackBar>

    <AppSnackBar
      class="articles__snackbar"
      color="var(--grey-70)"
      backgroundColor="var(--red-10)"
      :show="invalidPhrasesCount && showNotify"
      @hide="showNotify = false"
    >
      Отклонено артикулов для проверки: {{ invalidPhrasesCount }} <br />
      Менее 5 знаков
    </AppSnackBar>

    <AppInput
      label="Поиск артикулов"
      :description="showDescription"
      hasChips
      class="articles__input"
      :disabled="!file"
      v-model="currentPhrase"
      :loading="loader"
      :rules="inputRules"
      ref="articleInput"
    >
      <template slot="chip">
        <div class="articles__chips articles__chips_tablet">
          <AppTag
            class="articles__chip"
            v-for="(phrase, idx) in phrases.slice(0, 2)"
            :label="phrase.value"
            :key="phrase.id"
            close
            @close="removeArticlePhrase(idx)"
          />
          <div v-if="additionalCount > 0" class="articles__additional">
            <AppTag :label="`+${additionalCount}`" />
          </div>
        </div>

        <input
          :placeholder="
            phrases.length == 0 ? `Введите запрос и нажмите ${modeText}` : ''
          "
          v-model="currentPhrase"
          @keydown="handleKeydown"
          type="text"
          :disabled="!file"
          @paste.prevent="pasteArticles"
        />
      </template>
    </AppInput>

    <AppTextarea
      label="Поиск артикулов"
      :description="showDescription"
      :placeholder="phrases.length == 0 ? `Введите запрос и нажмите ${modeText}` : ''"
      class="articles__textarea articles__chips_mobile"
      v-model="currentPhrase"
      :rows="2"
      @keydown="handleKeydown"
      @paste.prevent="pasteArticles"
      hasChips
      :disabled="!file"
      :loading="loader"
      :rules="inputRules"
      ref=""
    >
      <template v-slot:chip>
        <AppTag
          class="articles__chip"
          v-for="(phrase, idx) in phrases.slice(0, 5)"
          :label="phrase.value"
          :key="phrase.id"
          close
          @close="removeArticlePhrase(idx)"
        />
        <div v-if="additionalCount > 0" class="articles__additional">
          <AppTag :label="`+${additionalCount}`" />
        </div>
      </template>
    </AppTextarea>

    <div class="articles__chips articles__chips_desktop">
      <AppTag
        class="articles__chip"
        v-for="(phrase, idx) in displayedPhrases"
        :label="phrase.value"
        :key="phrase.id"
        close
        @close="removeArticlePhrase(idx)"
      />
    </div>

    <AppButton
      class="articles__show-more"
      type="button"
      v-if="showMoreButton"
      @click="showMorePhrases"
      label="Показать еще"
      color="ghost"
    />

    <transition name="fade">
      <p v-if="file" class="articles__confirm-msg">
        Если все данные введены, вы можете начать проверку
      </p>
    </transition>
    
    <transition name="fade">
      <AppButton
        v-if="file"
        class="articles__btn"
        label="Проверить"
        :disabled="loader"
        @click="handleCheck"
      />
    </transition>
  </div>
</template>
<style scoped lang="scss">
.articles {
  &__search {
    position: relative;
  }

  &__snackbar {
    position: absolute;
    top: -50px;
    height: fit-content;

    @media (max-width: $mobile-width) {
      display: none;
    }

    .snackbar__body {
      padding: 0 !important;
    }
  }

  &__input {
    margin-bottom: 16px;

    @media (max-width: $mobile-width) {
      display: none;
      margin-bottom: 30px;
    }
  }

  &__textarea {
    margin-bottom: 30px;

    .input__wrapper {
      padding: 10px;
    }
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &_desktop {
      margin-bottom: 16px;

      &:has(.app-tag) {
        margin-bottom: 32px;
      }

      @media (max-width: $tablet-width) {
        display: none;
      }
    }

    &_tablet {
      display: none;
      margin-left: 6px;
      margin-bottom: unset;

      @media (max-width: $tablet-width) {
        display: inline-flex;
      }

      @media (max-width: $mobile-width) {
        display: none;
      }
    }

    &_mobile {
      display: none;

      @media (max-width: $mobile-width) {
        display: block;
        &:has(.app-tag) {
          margin-bottom: 24px;
        }
      }
    }
  }

  &__chip {
    overflow: hidden;
    max-width: 200px;
  }

  &__show-more {
    max-width: 163px;
    margin: 16px 0 32px;

    @media (max-width: $tablet-width) {
      display: none;
    }
  }
  &__btn {
    width: 134px;
    margin-bottom: 32px;
  }

  &__confirm-msg {
    @include font-description-semibold-0;
    margin-bottom: 16px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
