<script>
import AppDropFile from "@/components/ui/AppDropFile.vue";
import { bus } from "@/router/bus";

import ArticlesFormResult from "@/components/resources/articles/ArticlesFormResult.vue";
import ArticlesSearchField from "@/components/resources/articles/ArticlesSearchField.vue";
import ArticlesFaq from "@/components/resources/articles/ArticlesFaq.vue";
import ArticlesRadioGroup from "@/components/resources/articles/ArticlesRadioGroup.vue";

export default {
  name: "ArticlesForm.vue",

  components: {
    AppDropFile,
    ArticlesFormResult,
    ArticlesSearchField,
    ArticlesFaq,
    ArticlesRadioGroup,
  },

  data() {
    return {
      file: null,
      phrases: [],

      showResult: false,
      articlesResult: null,
      acceptedFiles: ".PDF, .DOCX",
      loader: false,
      inputMode: "enter",
    };
  },

  methods: {
    getArticles() {
      const formData = new FormData();
      formData.append("file", this.file);

      this.phrases.forEach(phrase => {
        formData.append("phrases", phrase.value);
      });

      this.loader = true;

      this.$postapi(this.$api_articles, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(data => {
          this.articlesResult = data;
          this.loader = false;
          this.showResult = true;
        })
        .catch(err => {
          bus.$emit("show_notify", {
            color: "error",
            notifytext:
              "Ошибка при отправке данных. Пожалуйста заполните все поля.",
          });
          this.loader = false;
        });
    },

    getArticleFile(file) {
      this.file = file;
    },

    removeArticleFile() {
      this.file = null;
      this.phrases = [];
    },

    resetForm(action) {
      if (action == "resetAll") {
        this.showResult = false;
        this.phrases = [];
        this.file = null;
        bus.$emit("resetFiles");
      } else if (action == "resetArticles") {
        this.phrases = [];
        this.showResult = false;
      }
    },

    updatePhrases(phrases) {
      this.phrases = phrases;
    },

    updateRadio(val) {
      this.inputMode = val;
    },
  },
};
</script>

<template>
  <div class="articles">
    <div class="articles__wrap">
      <form v-show="!showResult" @submit.prevent="getArticles">
        <h2 class="articles__title">Поиск по артикулам</h2>

        <p class="articles__description">
          Онлайн-сервис позволяет быстро проверить соответствие артикулов или
          моделей, указанных в таможенной декларации, данным из сертификатов
          соответствия, деклараций, лицензий, нотификаций ФСБ и других
          документов
        </p>

        <AppDropFile
          label="Загрузите документ"
          class="articles__file"
          :accept="acceptedFiles"
          @getArticleFile="getArticleFile"
          :hideArea="!!file"
          @removeArticleFile="removeArticleFile"
        />

        <ArticlesRadioGroup :disabled="!file" @update-radio="updateRadio" />

        <ArticlesSearchField
          :loader="loader"
          :file="file"
          @update-phrases="updatePhrases"
          :phrases="phrases"
          :mode="inputMode"
          @get-articles="getArticles"
        />
      </form>

      <ArticlesFaq v-if="!showResult" />

      <ArticlesFormResult
        v-if="showResult"
        @resetForm="resetForm"
        :result="articlesResult"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.articles {
  min-height: 400px;

  &__wrap {
    max-width: 1200px;
    margin: 60px auto;

    @media (max-width: $mobile-width) {
      margin: 24px auto;
    }
  }

  &__title {
    @include font-title-2;
    margin-bottom: 16px;
    color: var(--grey-70);

    @media (max-width: $tablet-width) {
      @include font-headline-1;
    }

    @media (max-width: $mobile-width) {
      @include font-headline-2;
    }
  }

  &__description {
    margin-bottom: 40px;
    @include font-body-2;

    @media (max-width: $tablet-width) {
      @include font-body-3;
    }
  }

  &__clear-field {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }

  &__file {
    margin-bottom: 4px;
  }
}
</style>
